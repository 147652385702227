<template>
  <div class="modal custom-alert" :id="'custom-alert-'+popupId" tabindex="-1" role="dialog" aria-labelledby="custom-alert" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content pay-modal-animate__wrapp">
        <div class="modal-title">Увага!</div>
        <hr class="d-none d-lg-block">
        <div class="modal-body">
          <p>{{ message }}</p>
          <button @click="closeAlert" class="btn btn-orange">Ок</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message', "popupId"],
  methods: {
    closeAlert() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.custom-alert{
  z-index: 999999;
}
.custom-alert .modal-content {
  width: 500px;
  margin: 0 auto;
  padding: 15px;
}
button.btn{
  display: block;
  margin: 0 auto;
  width: 150px;
}
.modal-body{
  text-align: justify;
}
.modal-title{
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}
</style>
